import React from 'react';
import './Privacy.css';
import contactBg from '../../../assets/contact_us_bg.png';
import appStore from '../../../assets/app_store.png';
import googlePlay from '../../../assets/google.png'




export default function Privacy() {
   const styles = {
      container: {
        backgroundImage: `url(${contactBg})`,
        backgroundRepeat: 'no-repeat', 
        width: '100%',  
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '200px',

      }
    };

 
  return (
    <div>
     <div className="contact_bg" style={styles.container}></div>
     <div className="privacy_container">
        <div className="privacy_box">
           <div className="privacy_header">
                <h3>Privacy Policy</h3>
           </div>
            <div className="privacy_content">

            <h3>Velox Solution Limited Delivery  App Policy Agreement</h3>

            <p>This Delivery App Policy Agreement outlines the terms and conditions under which users (both customers and delivery personnel) may use our app. By registering for and using our services, you agree to comply with this agreement, and any violation of these terms may result in termination of your account. </p>

            <div className='privacy_inner'>
            <h4>⁠ 1. ⁠User Account and Responsibilities</h4>
            <ul>
               <li>⁠Account Creation: Users must provide accurate information during account registration, including contact details, delivery addresses, and payment information.</li>
               <li>⁠Account Security: Users are responsible for maintaining the confidentiality of their login credentials. We are not liable for any unauthorized access or misuse of accounts.</li>
               <li>⁠Account Termination : Users may request to delete their account and associated data at any time. Upon deletion, all personal information and transaction history will be permanently removed, except where required by law.</li>
            </ul>
            </div>

            <div className='privacy_inner'>
            <h4>⁠ 2. ⁠Order Placement and Payment</h4>
               <ul>
                  <li>⁠Accurate Information : Customers are responsible for ensuring that order details, including delivery addresses and contact information, are accurate.</li>
                  <li>⁠Payment : All orders must be paid through the app at the time of checkout. We accept multiple payment options, which are listed on the platform.</li>
               </ul>
            </div>


            <div className='privacy_inner'>
            <h4>⁠ 3. ⁠⁠Delivery and Responsibilities</h4>
               <ul>
                  <li>⁠Delivery Timeframes : While we aim to deliver within the estimated timeframe, actual delivery times may vary due to external factors beyond our control.</li>
                  <li>⁠⁠User Availability : Customers must ensure they are available at the delivery location or designate someone else to receive the delivery. If no one is available, the delivery may be rescheduled at an additional cost.</li>
                  <li>⁠Damage or Loss : In the event of loss or damage to goods during transit, the company’s liability is limited to the cost of the goods.</li>
               </ul>
            </div>

            <div className='privacy_inner'>
            <h4>⁠ 4. ⁠Insurance and Liability</h4>
               <ul>
                  <li>⁠Optional Insurance: Users may choose to insure their items for delivery, covering damages or losses during transit.</li>
                  <li>⁠⁠Company Liability : The company will not be held liable for any losses or damages exceeding the insured value of the shipment or in cases where no insurance was purchased.</li>
               </ul>
            </div>

            <div className='privacy_inner'>
            <h4>⁠ 5. ⁠User Behavior</h4>
               <ul>
                  <li>⁠Respectful Communication : Users are expected to engage with delivery personnel, customer service agents, and other users respectfully and professionally.</li>
                  <li>⁠⁠Prohibited Conduct : Any abusive, fraudulent, or illegal behavior will result in immediate account suspension or termination.</li>
               </ul>
            </div>


            <div className='privacy_inner'>
            <h4>⁠ 6. Data Privacy</h4>
               <ul>
                  <li>⁠User Data: We are committed to protecting your personal information and will only use it in compliance with our [Privacy Policy].</li>
                  <li>⁠Data Sharing: We will not share user data with third parties, except as required by law or necessary for order fulfillment (e.g., sharing address with delivery personnel).</li>
               </ul>
            </div>

            <div className='privacy_inner'>
            <h4>⁠ 7. ⁠Amendments</h4>
               <ul>
                  <li>⁠We reserve the right to update this policy agreement at any time. Users will be notified of significant changes, and continued use of the app signifies acceptance of the updated terms.</li>
               </ul>
            </div>

            <div className='privacy_inner'>
            <h4>⁠ 8. ⁠⁠Dispute Resolution</h4>
               <ul>
                  <li> ⁠Mediation : Any disputes arising from the use of the app shall first be resolved through negotiation or mediation.</li>
                  <li>⁠Jurisdiction : In case of failure to resolve disputes through mediation, they shall be subject to the jurisdiction of the courts in [Jurisdiction] where the company is registered.</li>
               </ul>
            </div>


            <p>By accepting this agreement, users acknowledge that they have read and understood the terms and conditions and agree to abide by them during the use of the velox solution limited delivery app.</p>



            </div>
        </div>
     </div>
     <div className="app_section">
             <div className="app_box">
                  <div className="app_text">
                     <h3>Download Our mobile App</h3>
                     <p>Get exclusive access to logistics drivers with our mobile app. Download now and experience convenience on the go.</p>
                  </div>
                  <div className="mobile_app">
                    <img src={appStore} alt='app-store'/>
                    <img src={googlePlay} alt='google-play'/>
                  </div>
             </div>
     </div>
    </div>
  )
}
