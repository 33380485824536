import React , {useEffect ,useRef ,useState} from 'react'
import { Outlet ,Link } from 'react-router-dom'
import logo_v1 from '../../assets/logo_v1.png'
import logo_v2 from '../../assets/logo_v2.png'

export default function Layout() {

   const menuIconRef = useRef(null);
   const navBarRef = useRef(null);
   const [activeMenuItem, setActiveMenuItem] = useState('Home'); 
 
   useEffect(() => {
     const handleToggle = () => {
       menuIconRef.current.classList.toggle('bx-x');
       navBarRef.current.classList.toggle('active');
     };
 
     const menuIcon = menuIconRef.current;
 
     if (menuIcon) {
       menuIcon.addEventListener('click', handleToggle);
     }
 
     return () => {
       if (menuIcon) {
         menuIcon.removeEventListener('click', handleToggle);
       }
     };
   }, []);

   useEffect(() => {
         
      setActiveMenuItem('Home');
    }, []);

    const handleMenuClick = (menuItem) => {
      setActiveMenuItem(menuItem);
      menuIconRef.current.classList.remove('bx-x');
      navBarRef.current.classList.remove('active'); 
      
    };


  return (
    <div>
       <nav className="header" id="header">
           <div className="header_logo">
              <img src={logo_v1} alt='header-logo'/>
           </div>
           <i ref={menuIconRef} className='bx bx-menu' id='menu-icon'></i>
           <div ref={navBarRef}  className="menus navbar">
               <Link to='/' 
                 className={activeMenuItem === 'Home' ? 'active' : ''}
                 onClick={() => handleMenuClick('Home')}
               
               >Home</Link>
               <a>Discover</a>
               <a>How it works</a>
               <Link to='our-team'
                className={activeMenuItem === 'Team' ? 'active' : ''}
                onClick={() => handleMenuClick('Team')}
               >Our Team</Link>
               <Link to='contact-us'
                className={activeMenuItem === 'ContactUs' ? 'active' : ''}
                onClick={() => handleMenuClick('ContactUs')}
               >Contact Us</Link>
           </div>
           <div className="help_center">
              <button>Help Center</button>
           </div>
       </nav>
       {<Outlet/>}
       
       <footer className='footer'>
         <div className="footer_section">
            <div className="footer_menus">
               <div className="footer_menu">
                  <h3>Product</h3>
                  <a>Pricing</a>
                  <a>Solutions</a>
                  <a>Team Plans</a>
               </div>
               <div className="footer_menu">
                  <h3>About Us</h3>
                  <a>Pricing</a>
                  <a>Solutions</a>
                  <a>Team Plans</a>
               </div>
               <div className="footer_menu">
                  <h3>Help & Support</h3>
                  <a>Pricing</a>
                  <a>Solutions</a>
                  <a>Team Plans</a>
               </div>
               <div className="footer_menu">
                  <h3>Services</h3>
                  <a>Pricing</a>
                  <a>Solutions</a>
                  <a>Team Plans</a>
               
               </div>
            </div>
            <div className="logo_section">
               <img src={logo_v2} alt='footer-logo'/>
            </div>
          </div>
          <div className="copyright">
               <div className="copy">
                  <span>@copyright 2024</span>
                  <a>Help</a>
                  <Link to='privacy-policy'>Privacy</Link>
                  <a>Terms</a>
               </div>
               <div className='socials'>
                   <div className='social'>
                       <i className='bx bxl-facebook' ></i>
                       <i className='bx bxl-instagram'></i>
                       <i class='bx bxl-twitter' ></i>
                       <div>
                          <span>English (United States)</span>
                       </div>
                   </div>
               </div>
          </div>
       </footer>
    </div>
  )
}
